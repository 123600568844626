import React from 'react'
import SearchBar from '../../SearchBar'
import Title from '../../Title'
import IconList from './IconList'
import LinksOptions from '../options/LinksOptions'
import NotificationIcon from './NotificationIcon'
// import { useGetAllAnnouncementsQuery } from '../../../services/announcementApi'
import SearchIcon from './SearchIcon'

function TopBar() {
  return (
    <div className=" flex px-[16px] md:px-10 lg:px-16 justify-between items-center shadow-md bg-white z-50 left-0 right-0  h-16">
      <Title />
      {/* <SearchBar /> */}
      <div className=" md:block sm:hidden lg:flex lg:gap-8">
        <SearchBar />
      </div>

      <div className=" md:block sm:hidden lg:flex lg:gap-8">
        <IconList />
      </div>
      <div className="flex gap-2 items-center">
        <SearchIcon />
        <LinksOptions />
        <NotificationIcon />
      </div>
    </div>
  )
}

export default TopBar
