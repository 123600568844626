import { FaCartArrowDown } from 'react-icons/fa'
import { IoChatboxEllipses } from 'react-icons/io5'

let currentUser = JSON.parse(localStorage.getItem('currentUser'))

const links_options = (cart) => {
  return [
    {
      title: 'Panier',
      icon: (
        <div className="relative bg-green">
          {cart?.length > 0 && (
            <div
              className={` bg-red-500 absolute top-[-6px] left-[17px] text-[9px] py-[1px] text-white ${cart?.length > 9 ? 'px-[3px]' : 'px-[5px]'}  rounded-full`}
            >
              {cart?.length}
            </div>
          )}
          <FaCartArrowDown className="text-[25px] text-slate-500" />
        </div>
      ),
      url: '/',
    },
  ]
}

const links = (messages) => [
  {
    title: 'Messages',
    icon: (
      <div className="relative bg-green">
        {messages?.length > 0 && (
          <div
            className={` bg-red-500 absolute top-[-6px] left-[17px] text-[9px] py-[1px] text-white ${messages?.length > 9 ? 'px-[3px]' : 'px-[5px]'}  rounded-full`}
          >
            {messages?.length}
          </div>
        )}
        <IoChatboxEllipses className="text-[25px] text-slate-500" />
      </div>
    ),
    url: `/conversations/${currentUser?._id}`,
  },
]

export { links_options, links }
