import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import userSlice from '../features/user/user'
import onlineUsersSlice from '../features/onlineUsers/online'
import toastSlice from '../features/toast/toast'
import socketSlice from '../features/socket/socket'
import cartSlice from '../features/cart/cart'
import tokenFirebaseSlice from '../features/firebase/tokenFirebase'
import announcementSlice from '../features/announcement/announcement'
import PhoneLoginSlice from '../features/user/login-by-phone-number'
import { announcementApi } from '../services/announcementApi'
import isShowCommentSlice from '../features/comment/show-comment'
import idAnnouncementSlice from '../features/comment/get-params'
import messageSlice from '../features/messages/message'
import { categoryApi } from '../services/categoryApi'
import { currencyApi } from '../services/currencyApi'
import { shopApi } from '../services/shopApi'
import { promoApi } from '../services/promoApi'
import { cartApi } from '../services/cartApi'
import { userApi } from '../services/userApi'
import { orderApi } from '../services/orderApi'
import { shipmentApi } from '../services/shipmentApi'
import { deliverApi } from '../services/deliverApi'
import { paymentApi } from '../services/paymentApi'
import { notificationApi } from '../services/notificationApi'

//import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
import openCategoriesSlice from '../features/mainbar/open-categories'
import blurpageSlice from '../features/mainbar/blurpage'
import isShowCartSlice from '../features/announcement/component_cart'

// const persistConfig = {
//   key: 'uty',
//   storage,
// }

export const store = configureStore({
  reducer: {
    user: userSlice,
    onlineusers: onlineUsersSlice,
    toast: toastSlice,
    isshowcomment: isShowCommentSlice,
    idnnouncement: idAnnouncementSlice,
    socket: socketSlice,
    cart: cartSlice,
    tokenFirebase: tokenFirebaseSlice,
    phoneLogin: PhoneLoginSlice,
    announcement: announcementSlice,
    opencategories: openCategoriesSlice,
    blurpage: blurpageSlice,
    isshowcart: isShowCartSlice,
    message: messageSlice,
    [announcementApi.reducerPath]: announcementApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [currencyApi.reducerPath]: currencyApi.reducer,
    [shopApi.reducerPath]: shopApi.reducer,
    [promoApi.reducerPath]: promoApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [shipmentApi.reducerPath]: shipmentApi.reducer,
    [deliverApi.reducerPath]: deliverApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      announcementApi.middleware,
      categoryApi.middleware,
      currencyApi.middleware,
      shopApi.middleware,
      promoApi.middleware,
      currencyApi.middleware,
      cartApi.middleware,
      userApi.middleware,
      orderApi.middleware,
      shipmentApi.middleware,
      deliverApi.middleware,
      paymentApi.middleware,
      notificationApi.middleware
    ),
})

setupListeners(store.dispatch)

// export const persistor = persistStore(store)
export default store
