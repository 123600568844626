import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  useGetNotificationsQuery,
  useMarkReadedMutation,
} from '../../../services/notificationApi'
import { IoNotificationsSharp } from 'react-icons/io5'
import moment from 'moment'

export default function NotificationIcon() {
  const navigate = useNavigate()

  const currentUser = useSelector((state) => state.user.currentUser)
  const [isShowNotifications, setIsShowNotifications] = React.useState(false)
  const [loadingNotificationId, setLoadingNotificationId] = React.useState(null) // Track the notification being marked as read

  const { data: notifications } = useGetNotificationsQuery(currentUser?._id)
  const [markReaded] = useMarkReadedMutation()

  const markRead = async (notification) => {
    setLoadingNotificationId(notification?._id) // Set the loading ID
    await markReaded(notification?._id)
      .unwrap()
      .then(() => {
        navigate(`${notification?.url}`)
      })
      .catch(() => {
        setLoadingNotificationId(null) // Reset loading state on failure
      })
  }

  return (
    <div className="relative mr-3">
      <button
        onClick={() => setIsShowNotifications(!isShowNotifications)}
        className="text-slate-500 relative focus:outline-none"
        aria-label="Notifications"
      >
        <IoNotificationsSharp className="text-[25px]" />
        {notifications?.length > 0 && (
          <span className="absolute -top-1 -right-1 bg-yellow-400 text-white text-xs rounded-full px-1.5 py-0.5 font-bold">
            {notifications?.length}
          </span>
        )}
      </button>

      {isShowNotifications && (
        <div className="absolute z-50 top-full right-0 mt-2 w-70 max-h-80 bg-white rounded-lg shadow-lg border border-gray-200 overflow-y-auto">
          {notifications?.length === 0 ? (
            <p className="p-4 text-gray-500">Aucune notification.</p>
          ) : (
            <div className="flex flex-col">
              {notifications?.map((notification) => (
                <div
                  key={notification?._id}
                  className="border-b border-gray-200 last:border-b-0"
                >
                  <div
                    onClick={() => markRead(notification)}
                    className={`p-3 text-gray-700 hover:bg-gray-100 focus:outline-none ${
                      loadingNotificationId === notification?._id
                        ? 'cursor-wait'
                        : ''
                    }`}
                  >
                    {loadingNotificationId === notification?._id ? (
                      <p className="text-blue-500 font-medium text-sm">
                        Rédirection en cours...
                      </p>
                    ) : (
                      <>
                        <p className="font-medium text-sm truncate w-60">
                          {notification?.title}
                        </p>
                        <p className="text-xs text-gray-500">
                          {notification?.body}
                        </p>
                        <p className="text-xs text-gray-500">
                          {moment(notification?.createdAt).format('L')}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
